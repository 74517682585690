(function () {
    'use strict';

    const visitMedicalSocialPatientProfile = {
        bindings: {
            patient: '<',
            data: '=',
        },
        controller: VisitMedicalSocialPatientProfile,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-medical-social-patient-profile.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitMedicalSocialPatientProfile', visitMedicalSocialPatientProfile);

    VisitMedicalSocialPatientProfile.$inject = ['$rootScope', '_'];

    function VisitMedicalSocialPatientProfile($rootScope, _) {

        const vm = this;
        vm.step = 'PATIENT_PROFILE';

        vm.$onInit = function () {
            vm.currentPage = 1;
            vm.isSaving = false;

            initData();
        };

        /**
         * Initialisation des données
         */
        function initData() {
            if (!vm.data) {
                // initialisation des données à partir du profil patient si aucunes données n'a été enregistrées dans le draft de la visite
                vm.data = _.cloneDeep(vm.patient.details);
                vm.data.version = vm.patient.details.version ? vm.patient.details.version : 0;
            }

            // si on constate des modifications sur le profile patient
            const patientProfilModify = !!vm.patient.details.version && vm.patient.details.version > vm.data.version;

            if (patientProfilModify) {
                vm.oldData = _.cloneDeep(vm.data);
                vm.data = _.cloneDeep(vm.patient.details);
            }

            vm.showDataDiff = patientProfilModify;
        }

        /**
         * Action réalisée lorsqu'on clique sur le bouton "Valider"
         */
        vm.finish = function () {
            vm.deleteOldData();

            if (vm.patient.details.version && vm.patient.details.version > vm.data.version) {
                vm.data.initPatientProfilData = _.cloneDeep(vm.patient.details);
            }

            $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                step: vm.step
            });
        };

        /**
         * Nettoyage des anciennes données.
         */
        vm.deleteOldData = function() {

            if (vm.data) {
                delete vm.data.habitudes;
                delete vm.data.autonome;
                delete vm.data.rdv;
                delete vm.data.medic;
                delete vm.data.assistance;
                delete vm.data.assistanceInfo;
            }
        };
    }

})();
