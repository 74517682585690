(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientTherapyStartController', PatientTherapyStartController);

    PatientTherapyStartController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$filter', '$translate', '$log', 'entity', 'PatientTherapy', 'Therapy', 'Program', 'CareTeam', 'Visit', 'Practitioner'];

    function PatientTherapyStartController($timeout, $scope, $stateParams, $uibModalInstance, $filter, $translate, $log, entity, PatientTherapy, Therapy, Program, CareTeam, Visit, Practitioner) {
        const vm = this;

        vm.preview = preview;
        vm.clear = clear;
        vm.save = save;

        let deregisterContractLineWatch = null;
        let deregisterExecutorWatch = null;

        vm.patientTherapy = entity;
        vm.internalNote = $translate.instant("continuumplatformApp.patientTherapy.default.internalNote.started", {param: vm.patientTherapy.id});

        vm.assigneeId = vm.patientTherapy.assignee ? vm.patientTherapy.assignee.id : null;

        vm.$onInit = function () {
            if (vm.patientTherapy.day1Date === null) {
                vm.patientTherapy.day1Date = new Date();
            }

            resetPlan();
            loadProgram();
            loadTherapyContractLines().then(() => {
                if(vm.patientTherapy.therapyId) {
                    onTherapyIdChange(vm.patientTherapy.therapyId);
                }
                vm.unWatchTherapyId = $scope.$watch("vm.patientTherapy.therapyId", (newVal, oldVal) => {
                    if (newVal !== oldVal) {
                        onTherapyIdChange(newVal);
                    }
                });
                vm.unWatchDay1Date = $scope.$watch("vm.patientTherapy.day1Date", day1DateChange);
            });

            loadCoordinators();
        };

        vm.$onDestroy = function() {
            vm.unWatchTherapyId();
            vm.unWatchDay1Date();
        };

        function onTherapyIdChange (newVal) {
            if (deregisterContractLineWatch) {
                deregisterContractLineWatch();
                deregisterContractLineWatch = null;
            }
            if (deregisterExecutorWatch) {
                deregisterExecutorWatch();
                deregisterExecutorWatch = null;
            }
            const filteredTherapyContractLines = vm.therapyContractLines.filter((tcl) => tcl.therapyId === vm.patientTherapy.therapyId);
            if (filteredTherapyContractLines.length === 1) {
                vm.patientTherapy.contractLineId = filteredTherapyContractLines[0].lineId;
            } else {
                vm.patientTherapy.contractLineId = null;
            }

            if (newVal) {
                loadTherapy();
            } else {
                resetPlan();
            }
        }

        function day1DateChange(newVal, oldVal) {
            if (vm.visits.length && newVal && newVal !== oldVal) {
                $log.debug("day1Date changed, computing planning...");
                preview();
            }
        }

        function watchContractLine() {
            return $scope.$watch("vm.patientTherapy.contractLineId", (newVal, oldVal) => {
                if (vm.visits.length && newVal !== oldVal) {
                    $log.debug("contract line changed, computing planning...");
                    preview();
                }
            });
        }

        function watchExecutors() {
            return $scope.$watch("vm.selectedExecutors", (newVal, oldVal) => {
                if (newVal !== oldVal) {
                    $log.debug("executor changed, computing planning...");
                    preview();
                }
            }, true);
        }

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        function loadTherapyContractLines() {
            vm.therapyContractLines = [];
            return PatientTherapy.getTherapyContractLines({
                id: vm.patientTherapy.id,
                date: moment().format('YYYY-MM-DD')
            }).$promise.then(result => {
                vm.therapyContractLines = result
                    .sort((tcl1, tcl2) => tcl1.therapyName.localeCompare(tcl2.therapyName));

                const therapyIds = new Set();
                for (const item of vm.therapyContractLines) {
                    therapyIds.add(item.therapyId);
                }

                if (therapyIds.size === 1) {
                    vm.patientTherapy.therapyId = therapyIds.values().next().value;
                }
                vm.loadedTherapyContractLines = true;
            });
        }

        function loadProgram() {
            Program.get({
                id: vm.patientTherapy.programId
            }, program => {
                vm.program = program;
            });
        }

        function loadTherapy() {
            Therapy.get({
                id: vm.patientTherapy.therapyId
            }, therapy => {
                vm.therapy = therapy;

                CareTeam.getMembers(vm.patientTherapy.patientId).then(response => {
                    vm.team = response.data;

                    vm.selectedExecutors = {};

                    const visitTypesList = [...new Set(therapy.plan.visits.map(v => v.type).filter(t => t !== 'PRO'))];

                    const availableMembersPromise = [];

                    visitTypesList.forEach(t => {
                        availableMembersPromise.push(
                            Visit.availableMembers(t, vm.team, vm.patientTherapy.patient).then((availableMembers) => {
                                vm.visitTypes[t] = {
                                    options : availableMembers,
                                    assigneeRequired: null
                                };
                                vm.selectedExecutors[t] = availableMembers.length === 1 ? availableMembers[0].entity.id : null;
                                return availableMembers;
                            })
                        );
                    });

                    Promise.all(availableMembersPromise).then(() => {
                        // supression des types de visite obsolète (évite d'avoir un blink à l'écran avec l'appel à la fonction resetPlan())
                        Object.keys(vm.visitTypes).filter((t) => !visitTypesList.includes(t)).forEach((t) => {
                            delete vm.visitTypes[t];
                        });

                        preview();
                    });
                });
            });
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function preview() {
            // ne pas demander la preview si aucun parcours n'est sélectionné
            if (!vm.patientTherapy.therapyId) {
                return;
            }

            vm.hasVisitError = false;
            vm.patientUserNeeded = false;
            vm.isPreviewing = true;
            PatientTherapy.startPreview({
                id: vm.patientTherapy.id
            }, {
                day1Date: vm.patientTherapy.day1Date,
                therapyId: vm.patientTherapy.therapyId,
                contractLineId: vm.patientTherapy.contractLineId,
                executorIds: vm.selectedExecutors
            }, visits => {
                vm.visits = visits;
                for (const v of vm.visits) {
                    if (v.assigneeRequired && v.type !== 'PLUS_HOME') {
                        vm.visitTypes[v.type].assigneeRequired = true;
                    }
                    if (!vm.hasVisitError) {
                        if (v.assigneeRequired && !v.assigneeId) {
                            vm.hasVisitError = true;
                        }
                    }
                    if (v.type === 'PRO' && v.patientUserStatus === 'NO_USER') {
                        vm.patientUserNeeded = true;
                    }
                }
                vm.isPreviewing = false;

                $timeout(() => {
                    if (!deregisterContractLineWatch) {
                        deregisterContractLineWatch = watchContractLine();
                    }
                    if (!deregisterExecutorWatch) {
                        deregisterExecutorWatch = watchExecutors();
                    }
                });
            });
        }

        function save() {
            vm.isSaving = true;
            PatientTherapy.start({
                id: vm.patientTherapy.id
            }, {
                internalNote: vm.internalNote,
                day1Date: vm.patientTherapy.day1Date,
                contractLineId: vm.patientTherapy.contractLineId,
                patientStatus: vm.patientTherapy.patientStatus,
                executorIds: vm.selectedExecutors,
                patientEmail: vm.patientEmail,
                patientUserCanSendMessage: !!vm.patientUserCanSendMessage,
                assigneeId: vm.assigneeId,
            }, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess() {
            $scope.$emit('continuumplatformApp:patientTherapyUpdate');
            $scope.$emit('continuumplatformApp:visitUpdate');
            $scope.$emit('continuumplatformApp:internalNotesUpdate');
            $uibModalInstance.close();
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function resetPlan() {
            vm.visits = [];
            vm.visitTypes = {};
            vm.selectedExecutors = {};
            vm.patientUserNeeded = false;
            vm.hasVisitError = false;
        }

        function loadCoordinators() {
            vm.coordinators = [];

            Program.get({id: vm.patientTherapy.programId}, program => {
                CareTeam.getMembers(vm.patientTherapy.patientId).then(getMembersResponse => {
                    evaluateFacilityId(program, getMembersResponse);
                });
            });
        }

        function currentAssigneeOrActiveUser(practitioner) {
            return (vm.patientTherapy.assignee && vm.patientTherapy.assignee.id === practitioner.id)
                || !['NO_USER', 'DISABLED'].includes(practitioner.userStatus);
        }

        function addCurrentAssigneeIfNotInList() {
            if (vm.patientTherapy.assignee &&
                vm.patientTherapy.assignee.id
                && !vm.coordinators.map(c => c.id).includes(vm.patientTherapy.assignee.id)) {
                Practitioner.get({id: vm.patientTherapy.assignee.id}, practitioner => {
                    vm.coordinators.push(practitioner);
                });
            }
        }

        function evaluateFacilityId(program, getMembersResponse) {
            let facilityId;
            if (program.coordinationFacilityId && isFacilityInTeam(program.coordinationFacilityId, getMembersResponse)) {
                facilityId = program.coordinationFacilityId;
            } else if (isFacilityInTeam(vm.patientTherapy.patient.healthFacilityId, getMembersResponse)) {
                facilityId = vm.patientTherapy.patient.healthFacilityId;
            }

            if (facilityId) {
                findPractitioners(facilityId);
            } else {
                filterMembers(getMembersResponse);
            }
        }

        function isFacilityInTeam(facilityId, getMembersResponse) {
            return getMembersResponse.data.filter(m => m.entity.id === facilityId ).length > 0;
        }

        function findPractitioners(facilityId) {
            Practitioner.query({
                "job.in": ['J21', 'J38', 'J60'],
                "healthFacilityId.equals": facilityId
            }, practitioners => {
                vm.coordinators = practitioners.filter(currentAssigneeOrActiveUser);
                addCurrentAssigneeIfNotInList();
            });
        }

        function filterMembers(getMembersResponse) {
            vm.coordinators = getMembersResponse.data
                .filter(m => ['NURSE_COORD', 'FACILITY_PHARMACIST'].includes(m.role))
                .filter(currentAssigneeOrActiveUser)
                .map(m => m.entity);
            addCurrentAssigneeIfNotInList();
        }
    }
})();
