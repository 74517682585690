(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('TherapyController', TherapyController);

    TherapyController.$inject = ['$filter', '$scope', 'Therapy'];

    function TherapyController($filter, $scope, Therapy) {

        const vm = this;

        vm.therapies = null;
        vm.filters = {
            text: null,
            programId: null,
            status: ['DRAFT', 'ACTIVE'],
            hdj: null
        };
        vm.statuses = ['DRAFT', 'ACTIVE', 'DISABLED'];

        vm.unwatchText = $scope.$watch('vm.filters.text', onFiltersChange);
        vm.unwatchProgram = $scope.$watch('vm.filters.programId', onFiltersChange);
        vm.unwatchStatus = $scope.$watchCollection("vm.filters.status", onFiltersChange);
        vm.unwatchHdj = $scope.$watch('vm.filters.hdj', onFiltersChange);

        vm.$onDestroy = () => {
            if (vm.unwatchText) {
                vm.unwatchText();
            }
            if (vm.unwatchProgram) {
                vm.unwatchProgram();
            }
            if (vm.unwatchStatus) {
                vm.unwatchStatus();
            }
            if (vm.unwatchHdj) {
                vm.unwatchHdj();
            }
        };

        loadAll();

        function loadAll() {
            Therapy.query({
                "sort": ["program.name", "name"]
            }, result => {
                vm.therapies = result;
                vm.programs = $filter('unique')(vm.therapies, 'programId')
                    .reduce((total, currentValue, currentIndex, arr) => {
                        total.push({
                            id: currentValue.programId,
                            name: currentValue.programName
                        });
                        return total;
                    }, []);

                applyFilters();
            });
        }

        function onFiltersChange(newVal, oldVal) {
            if(newVal === oldVal) {
                return;
            }
            applyFilters();
        }

        function applyFilters() {
            vm.filteredTherapies = vm.therapies
                .filter(filterText)
                .filter(filterProgram)
                .filter(filterStatus)
                .filter(filterHdj);
        }

        function filterText(therapy) {
            return !vm.filters.text
                || therapy.code.toUpperCase().includes(vm.filters.text.toUpperCase())
                || therapy.name.toUpperCase().includes(vm.filters.text.toUpperCase());
        }

        function filterProgram(therapy) {
            return vm.filters.programId === null || therapy.programId === vm.filters.programId;
        }

        function filterStatus(therapy) {
            return vm.filters.status.includes(therapy.status);
        }

        function filterHdj(therapy) {
            return vm.filters.hdj === null || therapy.hdj === vm.filters.hdj;
        }
    }
})();
