(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('TermsController', TermsController);

    TermsController.$inject = ['$rootScope', '$sce', '$state', 'termsConstants', 'Account', 'StateSaver', 'Principal'];

    function TermsController($rootScope, $sce, $state, termsConstants, Account, StateSaver, Principal) {
        const vm = this;

        vm.accept = accept;

        vm.$onInit = () => {
            vm.isAccepting = false;
            vm.marketingAgreement = false;
            vm.patientDataAgreement = false;
            loadUserAccount();
        };

        function loadUserAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;

                if (vm.account && vm.account.practitioner) {
                    vm.version = termsConstants.versionPractitioner;
                    vm.trustedInlineUrl = $sce.trustAsResourceUrl(termsConstants.inlineUrlPractitioner);
                    vm.needMarketingAgreement = account.marketingAgreement === null;

                } else if (vm.account && vm.account.patient) {
                    vm.version = termsConstants.versionPatient;
                    vm.trustedInlineUrl = $sce.trustAsResourceUrl(termsConstants.inlineUrlPatient);
                    vm.needPatientDataAgreement = !account.patientDataAgreement;
                } else {
                    vm.version = 999999999;
                }
            });
        }

        function accept() {
            if(!vm.version) {
                return;
            }
            vm.isAccepting = true;
            Account.acceptTerms({acceptedTermsVersion : vm.version, marketingAgreement: vm.marketingAgreement, patientDataAgreement: vm.patientDataAgreement}, onAcceptSuccess, onAcceptError);
        }

        function onAcceptSuccess() {
            // on force le rechargement du compte utilisateur sinon account.acceptedTermsVersion n'est pas à jour
            // et on est redirigé à nouveau vers la validation des CGU.
            Principal.identity(true).then(() => {
                vm.isAccepting = false;
                $rootScope.termsPendingAccept = false;

                const previousState = StateSaver.getPreviousState();

                if (previousState && previousState.name !== 'terms') {
                    StateSaver.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                } else {
                    $state.go('home');
                }
            });
        }

        function onAcceptError() {
            vm.isAccepting = false;
        }

    }
})();
