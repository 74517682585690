(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PractitionerPlanningController', PractitionerPlanningController);

    PractitionerPlanningController.$inject = ['$scope', '$log', '$uibModal', 'Principal', 'Visit', 'DownloadFile'];

    function PractitionerPlanningController($scope, $log, $uibModal, Principal, Visit, DownloadFile) {

        const vm = this;

        vm.loading = true;
        vm.loadingHistory = false;
        vm.historyShown = false;
        vm.visits = null;
        vm.mustSignContract = false;

        vm.isStartable = Visit.isStartable;
        vm.isResumable = Visit.isResumable;

        vm.$onInit = () => {
            getAccount()
                .then(() => getCurrentVisits().then((visits) => {
                    vm.visits = split(filterAdhoc(visits));
                    vm.mustSignContract = mustSignContract(visits);
                }))
                .finally(() => {
                    vm.loading = false;
                });
        };

        vm.$onDestroy = () => {
            // nothing to do
        };

        vm.showHistory = () => {
            vm.loadingHistory = true;
            getHistory().then(visits => {
                vm.historyShown = true;
                vm.visits.push(...visits);
            }).finally(() => {
                vm.loadingHistory = false;
            });
        };

        /**
         * Open the plan dialog for a visit.
         * @param visit - the visit to plan.
         */
        vm.plan = (visit) => {
            $uibModal.open({
                templateUrl: 'app/activity/visit/visit-plan-dialog.html',
                controller: 'VisitPlanDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    visit: ['Visit', function (Visit) {
                        return Visit.get({id: visit.id}).$promise;
                    }],
                }
            }).result.then(() => {
                reload();
            }, () => {
                // do nothing
            });
        };

        vm.exportPdf = (visit) => {
            vm.isExportingPdf = true;
            Visit.exportPdf({
                id: visit.id
            }).$promise
                .then(DownloadFile.downloadFile)
                .finally(function () {
                    vm.isExportingPdf = false;
                });
        };

        /**
         * Get the user account.
         * @return {*} a promise with the user account.
         */
        function getAccount() {
            return Principal.identity().then(function (account) {
                if (account) {

                    if (!account.practitioner) {
                        throw new Error("L'utilisateur n'est pas un professionnel de santé");
                    }

                    vm.practitioner = account.practitioner;
                    vm.isDoctor = account.practitioner.job === 'J10';
                    vm.isPharmacist = account.practitioner.job === 'J21';
                    vm.isNurse = account.practitioner.job === 'J60';
                    vm.isOtherPractitioner = account.practitioner.job === 'J99';
                }
                return vm.account = account;
            });
        }

        function getCurrentVisits() {
            return Visit.query({
                "status.in": ['TODO', 'IN_PROGRESS'],
                feasibleByPractitionerId: vm.practitioner.id,
                size: 1000,
                sort: ['orderingDate,asc', 'id,asc'],
            }).$promise;
        }

        function getHistory() {
            return Visit.query({
                "status.in": ['DONE', 'CANCELED', 'EXPIRED'],
                feasibleByPractitionerId: vm.practitioner.id,
                size: 1000,
                sort: ['orderingDate,desc', 'id,desc'],
            }).$promise;
        }

        function filterAdhoc(visits) {
            return visits.filter((visit) => !visit.adhoc || visit.assigned);
        }

        function split(visits) {
            return [

                // questionnaires en cours que le professionnel peut renseigner
                ...visits.filter((visit) => ['IN_PROGRESS'].includes(visit.status) && visit.assigned),

                // questionnaires à renseigner, sauf consultation d'annonce et CPPP si pas pharmacien
                ...visits.filter((visit) =>
                    visit.status === 'TODO'
                    && visit.type !== 'ANNOUNCEMENT_CONSULTATION'
                    && (vm.isPharmacist || visit.name !== 'Consultation pharmaceutique de primo-prescription')),

            ];
        }

        /**
         * Check if the practitioner need to sign a contract for a visit
         * @param visits - The visits to check.
         * @return {boolean} true if the user need to sign a contract for a visit, false otherwise.
         */
        function mustSignContract(visits) {
            for (const v of visits) {
                if (['TODO', 'IN_PROGRESS'].includes(v.status) && v.assigned && !v.signed) {
                    return true;
                }
            }
            return false;
        }

        function reload() {
            getCurrentVisits().then((visits) => {
                vm.visits = split(filterAdhoc(visits));
            });
        }

    }
})();
