(function () {
    'use strict';

    var prescriptionsBlock = {
        bindings: {
            patientId: '=',
            open: '=?',
            notify: '=?',
            expand: '='
        },
        controller: PrescriptionsBlockController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/prescription/prescriptions-block.html'
    };

    angular
        .module('continuumplatformApp')
        .component('prescriptionsBlock', prescriptionsBlock);

    PrescriptionsBlockController.$inject = ['$rootScope', '$scope', '$state', '$log', '$uibModal', '$sce', 'Principal', 'Prescription', 'Media'];

    function PrescriptionsBlockController($rootScope, $scope, $state, $log, $uibModal, $sce, Principal, Prescription, Media) {

        var vm = this;

        var unsubscribe = $rootScope.$on('continuumplatformApp:prescriptionUpdate', load);
        $scope.$on('$destroy', function (event) {
            unsubscribe(event);
        });

        vm.$onInit = function () {
            load();

            vm.open = vm.open || function (prescriptionId) {
                Prescription.get({id: prescriptionId}, (prescription) => {
                    if (!prescription.mediaId
                        || (prescription.mediaContentType
                            && (prescription.mediaContentType === 'application/pdf' || prescription.mediaContentType.startsWith('image/')))) {
                        $state.go('prescription-detail', {id: prescriptionId});
                    } else if (prescription.mediaId) {
                        vm.trustedUrl = $sce.trustAsResourceUrl('/api/media/' + prescription.mediaId + '/download?disposition=attachment');
                        vm.downloadURI(vm.trustedUrl);
                    }
                });
            };
        };

        function load() {
            Prescription.query({
                "patientId.equals": vm.patientId,
                "sort": ["date,desc", "id,desc"],
                size: 1000
            }, function (value) {
                vm.prescriptions = value;
            });
        }

        vm.importPrescription = function () {
            Principal.identity().then(function (account) {
                $uibModal.open({
                    templateUrl: 'app/activity/prescription/prescription-dialog.html',
                    controller: 'PrescriptionImportController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('prescription');
                            return $translate.refresh();
                        }],
                        entity: function () {
                            return {
                                date: new Date(),
                                internalId: null,
                                comment: null,
                                status: null,
                                id: null,
                                patientId: vm.patientId
                            };
                        },
                        account: function () {
                            return account;
                        },
                        notify: function () {
                            return vm.notify;
                        }
                    }
                }).result.then(function (result) {

                }, function () {

                });
            });
        };

        vm.startPrescription = function () {
            Prescription.start({
                patientId: vm.patientId
            }, {}, function (prescription) {
                $state.go('prescription-detail', {id: prescription.id});
            });
        };

        vm.downloadURI = (uri, name) => {
            const downloadLink = angular.element('<a></a>');
            downloadLink.attr('href', uri);
            if (name) {
                downloadLink.attr('download', name);
            }
            downloadLink[0].click();
        };

    }

})();
