(function () {
    'use strict';

    var visitImportPrescriptions = {
        bindings: {
            patient: '<'
        },
        controller: VisitImportPrescriptionsController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-import-prescriptions.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitImportPrescriptions', visitImportPrescriptions);

    VisitImportPrescriptionsController.$inject = ['$rootScope', '$sce', 'Prescription', 'Media'];

    function VisitImportPrescriptionsController($rootScope, $sce, Prescription, Media) {

        const vm = this;

        vm.step = 'IMPORT_PRESCRIPTIONS';

        vm.finish = () => {
            $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                step: vm.step
            });
        };

        vm.open = (prescriptionId) => {
            Prescription.get({id: prescriptionId}, (prescription) => {

                if (!prescription.mediaId) {
                    vm.openModal(prescription, null);

                } else if (prescription.mediaId && (prescription.mediaContentType === 'application/pdf' || prescription.mediaContentType.startsWith('image/'))) {
                    Media.get({id: prescription.mediaId}, (media) => vm.openModal(prescription, media));

                } else if (prescription.mediaId) {
                    $rootScope.warnBeforeUnload = false;
                    vm.trustedInlineUrl = $sce.trustAsResourceUrl(`/api/media/${prescription.mediaId}/download?disposition=attachment`);
                    vm.downloadURI(vm.trustedInlineUrl);
                    $rootScope.warnBeforeUnload = true;
                }
            });
        };

    }

})();
