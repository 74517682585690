(function() {
    'use strict';

    angular.module('continuumplatformApp')
        .controller('PrescriptionsDetailModalController', PrescriptionsDetailModalController);

    PrescriptionsDetailModalController.$inject = ['$rootScope', '$scope', '$window', '$document', '$sce', '$timeout', '$uibModalInstance', 'entity', 'Prescription', 'Patient', 'PrescriptionItem', 'HealthFacility', 'Media'];

    function PrescriptionsDetailModalController($rootScope, $scope, $window, $document, $sce, $timeout, $uibModalInstance, entity, Prescription, Patient, PrescriptionItem, HealthFacility, Media) {
        var vm = this;

        vm.prescription = entity.prescription;
        vm.media = entity.media;
        vm.patient = Patient.get({id: vm.prescription.patientId});
        vm.anonymous = false;

        vm.back = back;
        vm.print = print;

        loadItems();

        if (vm.prescription.mediaId) {
            vm.trustedInlineUrl = $sce.trustAsResourceUrl('/api/media/' + vm.prescription.mediaId + '/download?disposition=inline');

        } else if (vm.prescription.prescriber && vm.prescription.prescriber.healthFacilityId) {
            vm.healthFacility = HealthFacility.get({id: vm.prescription.prescriber.healthFacilityId, view: 'summary'});
        }

        function loadItems() {
            PrescriptionItem.query({
                "prescriptionId.equals": vm.prescription.id,
                "sort": "createdDate"
            }, function (items) {
                vm.prescription.items = items;
            });
        }

        function back() {
            $uibModalInstance.close('back');
        }

        function print(anonymous) {
            var patientBlock;
            if (anonymous) {
                patientBlock = angular.element('#patientBlock');
                patientBlock.remove();
            }

            var printContents = angular.element('#printPrescription').html();

            if (anonymous) {
                angular.element('#patientPanel').prepend(patientBlock);
            }

            var popupWin = $window.open('', '_blank', 'width=1024,height=700');
            popupWin.document.open();
            popupWin.document.write('<html><head><link rel="stylesheet" href="content/css/vendor.css"><link rel="stylesheet" href="content/css/main.css"></head><body onload="window.print()">' + printContents + '</body></html>');
            popupWin.document.close();

        }

    }
})();
