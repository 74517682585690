(function () {
    'use strict';

    const visitEndReport = {
        bindings: {
            data: '=',
            questions: '<',
        },
        controller: VisitEndReportController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-end-report.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitEndReport', visitEndReport);

    VisitEndReportController.$inject = ['$rootScope'];

    function VisitEndReportController($rootScope) {

        const vm = this;

        /* *************************************************************
         * Attributs
         ***************************************************************/
        vm.wizardStep = 0;
        vm.visitStep = 'END_REPORT';
        vm.possibleScores = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

        vm.step1Questions = ['Q1', 'Q2', 'Q3', 'Q4', 'Q5', 'Q6'];
        vm.step2Questions = ['Q7_01', 'Q7_02'];

        vm.step2QuestionsOption = [
            "Calendrier/Agenda répertoriant les dates de prise du traitement, les dates des bilans sanguins, les dates de RDV",
            "Application mobile de rappel de prise du traitement",
            "Alarme du téléphone pour rappeler la prise du traitement",
            "Carnet de suivi remis par son hôpital ou la pharmacie",
            "Autres",
            "Pas d’outil"
        ];

        vm.step2QuestionsSelectedOptions = [];

        vm.isStep1Valid = false;

        vm.$onInit = function () {
            vm.data = vm.data ? vm.data : {};

            if (vm.data.Q7_01) {
                vm.data.Q7_01.split('\n').forEach( (option) => {
                    if (vm.step2QuestionsOption.indexOf(option.trim()) >= 0) {
                        vm.step2QuestionsSelectedOptions[vm.step2QuestionsOption.indexOf(option.trim())] = option;
                    }

                });

            }
        };

        /* *************************************************************
         * Fonctions
         ***************************************************************/

        vm.onClickScore = (question, score) => {
            vm.data[question] = score;
        };

        vm.onClickOptionStep2 = (index, selectedOption) => {
            // on supprime le détail si on décoche l'option "Autre"
            if (vm.step2QuestionsSelectedOptions.includes(selectedOption) && selectedOption === 'Autres') {
                vm.data.Q7_02 = undefined;
            }

            if (selectedOption === 'Pas d’outil' && !vm.step2QuestionsSelectedOptions[index]) {
                // si pas d'outil on décoche tous les autres options
                vm.step2QuestionsSelectedOptions = [];
            } else if (selectedOption !== 'Pas d’outil' && vm.step2QuestionsSelectedOptions.includes('Pas d’outil')) {
                // si pas d'outil est coché et qu'on a choisi une autre valeur on décoche "pas d'outil"
                vm.step2QuestionsSelectedOptions = [];
            }



            if (vm.step2QuestionsSelectedOptions[index]) {
                vm.step2QuestionsSelectedOptions[index] = undefined;
            } else {
                vm.step2QuestionsSelectedOptions[index] = selectedOption;
            }

            // on génère la valeur à stocker
            vm.data.Q7_01 = vm.step2QuestionsSelectedOptions.reduce((accumulateur, valeurCourante) => valeurCourante ? `${accumulateur}\n${valeurCourante}` : accumulateur, '');
        };

        vm.next = () => {
            vm.wizardStep++;
        };

        vm.previous = () => {
            vm.wizardStep--;
        };

        vm.finish = () => {
            $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                step: vm.visitStep
            });
        };

    }

})();
