(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientTherapyListController', PatientTherapyListController);

    PatientTherapyListController.$inject = ['$log', '$scope', '$state', '$stateParams', '$uibModal', '$q', '$window', 'PatientTherapy', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'Prescription', 'HealthFacility', 'Principal', 'Practitioner', 'HealthEntityContract', 'PharmacyOrder', 'CareTeam', 'Visit', 'Therapy', 'DownloadFile'];

    function PatientTherapyListController($log, $scope, $state, $stateParams, $uibModal, $q, $window, PatientTherapy, ParseLinks, AlertService, paginationConstants, pagingParams, Prescription, HealthFacility, Principal, Practitioner, HealthEntityContract, PharmacyOrder, CareTeam, Visit, Therapy, DownloadFile) {

        const vm = this;

        vm.openPractitioner = openPractitioner;
        vm.openFacility = openFacility;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = 10;

        vm.filters = $stateParams.filters;

        vm.statuses = ['S11_VALIDATED', 'S20_ACTIVE', 'S30_PAUSED', 'S40_FINISHED', 'S50_STOPPED', 'S99_CANCELED'];

        vm.loadAll = loadAll;
        vm.onFiltersChange = onFiltersChange;
        vm.downloadCsv = downloadCsv;

        vm.$onInit = function () {
            Principal.identity().then(function (account) {

                vm.isAdmin = account.authorities.includes('ROLE_ADMIN');
                vm.practitioner = account.practitioner;
                if (vm.practitioner) {
                    vm.isDoctor = vm.practitioner.job === 'J10';
                    vm.isCoordination = vm.practitioner.healthFacilityType === 'COORDINATION';
                }

                if (angular.isUndefined(vm.filters.assigneeId) && vm.isCoordination) {
                    vm.filters.assigneeId = vm.practitioner.id;
                }

                if (account.settings) {
                    if (account.settings.diseaseDivisionName) {
                        // load disease division name from account settings
                        vm.diseaseDivisionName = account.settings.diseaseDivisionName;
                    }
                    if (account.settings.trial) {
                        // load trial from account settings
                        vm.filters.trial = account.settings.trial;
                    } else {
                        vm.filters.trial = undefined;
                    }
                }

                loadDiseaseDivisionsAndTrials(vm.practitioner.healthFacilityId).then(() => {
                    loadAll();
                });
            });

            vm.unwatchStatus = $scope.$watchCollection("vm.filters.status", function (oldValue, newValue) {
                onFiltersChange();
            });

            vm.unwatchHdj = $scope.$watch('vm.filters.hdj', (newVal, oldVal) => {
                if (oldVal !== newVal) {
                    onFiltersChange();
                }
            });

        };

        vm.$onDestroy = () => {
            if (vm.unwatchDiseaseDivisionName) {
                vm.unwatchDiseaseDivisionName();
            }
            if (vm.unwatchTrial) {
                vm.unwatchTrial();
            }
            if (vm.unwatchStatus) {
                vm.unwatchStatus();
            }
            if (vm.unwatchHdj) {
                vm.unwatchHdj();
            }
        };

        function loadDiseaseDivisionsAndTrials(healthFacilityId) {
            if (!healthFacilityId) {
                vm.enableDiseaseDivisionFilter = false;
                vm.diseaseDivisionName = undefined;
                vm.filters.diseaseIds = undefined;

                vm.enableTrialFilter = false;
                vm.filters.trial = undefined;
                return Promise.resolve();
            }
            return HealthFacility.get({
                view: 'summary',
                id: healthFacilityId
            }, facility => {

                facility.diseaseDivisions = facility.diseaseDivisions || [];

                vm.enableDiseaseDivisionFilter = facility.diseaseDivisions.length > 0;

                // group by division name reduce to 1 division by name
                vm.divisions = facility.diseaseDivisions.reduce((divisions, division) => {
                    divisions[division.name] = division.diseaseIds;
                    return divisions;
                }, {});

                // restore previously selected division with updated data
                if(vm.diseaseDivisionName) {
                    if(vm.divisions[vm.diseaseDivisionName]) {
                        vm.filters.diseaseId = undefined;
                        vm.filters.diseaseIds = vm.divisions[vm.diseaseDivisionName];
                    } else {
                        vm.diseaseDivisionName = undefined;
                        vm.filters.diseaseIds = undefined;
                    }
                }

                vm.unwatchDiseaseDivisionName = $scope.$watch('vm.diseaseDivisionName', onChangeDivision);

                // trials
                vm.enableTrialFilter = facility.trials.length > 0;
                vm.trials = facility.trials;

                vm.unwatchTrial = $scope.$watch('vm.filters.trial', onChangeTrial);

            }).$promise;
        }

        function onChangeDivision(newVal, oldVal) {
            if(newVal === oldVal) {
                return;
            } else if (newVal) {
                vm.filters.diseaseId = undefined;
                vm.filters.diseaseIds = vm.divisions[newVal];
            } else {
                vm.filters.diseaseIds = undefined;
            }
            storeDiseaseDivisionName(newVal);
            onFiltersChange();
        }

        function storeDiseaseDivisionName(value) {
            if(value)
                Principal.updateSetting('diseaseDivisionName', value);
            else
                Principal.deleteSetting('diseaseDivisionName');
        }

        function onChangeTrial(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }
            storeTrial(newVal);
            onFiltersChange();
        }

        function storeTrial(value) {
            if (value)
                Principal.updateSetting('trial', value);
            else
                Principal.deleteSetting('trial');
        }

        function onFiltersChange() {
            if (vm.page) {
                if (vm.page === 1) {
                    loadAll();
                } else {
                    // reset page number
                    vm.page = 1;
                    // change url
                    vm.transition();
                }
            }

        }

        function loadAll() {

            if(vm.isLoading) {
                $log.warn('Already loading, skipped.');
                return;
            }

            vm.isLoading = Date.now();

            vm.patientTherapies = null;

            if (vm.isAdmin) {
                PatientTherapy.queryDistinctFacilities({
                    "status.in": vm.filters.status,
                    "patientStatus.equals": vm.filters.patientStatus === null ? undefined : vm.filters.patientStatus,
                    "patientPostalCode.startsWith": !vm.filters.patientPostalCode ? undefined : vm.filters.patientPostalCode,
                    "patientCity.containsNormalized": !vm.filters.patientCity ? undefined : vm.filters.patientCity,
                    "diseaseId.equals": vm.filters.diseaseId === null ? undefined : vm.filters.diseaseId,
                    "diseaseId.in": vm.filters.diseaseIds,
                    "trial.equals": vm.filters.trial === null ? undefined : vm.filters.trial,
                    "doctorId.equals": vm.filters.prescriberId === null ? undefined : vm.filters.prescriberId,
                    "programId.equals": vm.filters.programId === null ? undefined : vm.filters.programId,
                    "assigneeId.equals": !vm.filters.assigneeId || vm.filters.assigneeId === 'notAssignee' ? undefined : vm.filters.assigneeId,
                    "assigneeId.specified": vm.filters.assigneeId === 'notAssignee' ? false : undefined,
                }, function (result) {
                    vm.healthfacilities = result;
                });
            }

            PatientTherapy.queryDistinctAssignees({
                "status.in": vm.filters.status,
                "patientStatus.equals": vm.filters.patientStatus === null ? undefined : vm.filters.patientStatus,
                "patientPostalCode.startsWith": !vm.filters.patientPostalCode ? undefined : vm.filters.patientPostalCode,
                "patientCity.containsNormalized": !vm.filters.patientCity ? undefined : vm.filters.patientCity,
                "healthFacilityId.equals": vm.filters.healthFacilityId === null ? undefined : vm.filters.healthFacilityId,
                "diseaseId.equals": vm.filters.diseaseId === null ? undefined : vm.filters.diseaseId,
                "diseaseId.in": vm.filters.diseaseIds,
                "trial.equals": vm.filters.trial === null ? undefined : vm.filters.trial,
                "programId.equals": vm.filters.programId === null ? undefined : vm.filters.programId,
                "doctorId.equals": vm.filters.prescriberId === null ? undefined : vm.filters.prescriberId
            }, function (result) {
                vm.assignees = result;

                // Si l'utilisateur fait partie de la structure de coordination C+ on le rajoute à la liste s'il n'en fait partie
                if (vm.isCoordination) {
                    const isUserInAssignees = vm.assignees.filter((p) => p.id === vm.practitioner.id).length;
                    if (!isUserInAssignees) {
                        vm.assignees.push(vm.practitioner);
                    }
                }
            });

            PatientTherapy.queryDistinctPrescribers({
                "status.in": vm.filters.status,
                "patientStatus.equals": vm.filters.patientStatus === null ? undefined : vm.filters.patientStatus,
                "patientPostalCode.startsWith": !vm.filters.patientPostalCode ? undefined : vm.filters.patientPostalCode,
                "patientCity.containsNormalized": !vm.filters.patientCity ? undefined : vm.filters.patientCity,
                "healthFacilityId.equals": vm.filters.healthFacilityId === null ? undefined : vm.filters.healthFacilityId,
                "diseaseId.equals": vm.filters.diseaseId === null ? undefined : vm.filters.diseaseId,
                "diseaseId.in": vm.filters.diseaseIds,
                "trial.equals": vm.filters.trial === null ? undefined : vm.filters.trial,
                "programId.equals": vm.filters.programId === null ? undefined : vm.filters.programId,
                "assigneeId.equals": !vm.filters.assigneeId || vm.filters.assigneeId === 'notAssignee' ? undefined : vm.filters.assigneeId,
                "assigneeId.specified": vm.filters.assigneeId === 'notAssignee' ? false : undefined
            }, function (result) {
                vm.prescribers = result;
            });

            if(!vm.enableDiseaseDivisionFilter) {
                PatientTherapy.queryDistinctDiseases({
                    "status.in": vm.filters.status,
                    "patientStatus.equals": vm.filters.patientStatus === null ? undefined : vm.filters.patientStatus,
                    "patientPostalCode.startsWith": !vm.filters.patientPostalCode ? undefined : vm.filters.patientPostalCode,
                    "patientCity.containsNormalized": !vm.filters.patientCity ? undefined : vm.filters.patientCity,
                    "healthFacilityId.equals": vm.filters.healthFacilityId === null ? undefined : vm.filters.healthFacilityId,
                    "trial.equals": vm.filters.trial === null ? undefined : vm.filters.trial,
                    "doctorId.equals": vm.filters.prescriberId === null ? undefined : vm.filters.prescriberId,
                    "programId.equals": vm.filters.programId === null ? undefined : vm.filters.programId,
                    "assigneeId.equals": !vm.filters.assigneeId || vm.filters.assigneeId === 'notAssignee' ? undefined : vm.filters.assigneeId,
                    "assigneeId.specified": vm.filters.assigneeId === 'notAssignee' ? false : undefined,
                    "sort": "disease.name",
                }, function (result) {
                    vm.diseases = result;
                });
            }

            PatientTherapy.queryDistinctPrograms({
                "status.in": vm.filters.status,
                "patientStatus.equals": vm.filters.patientStatus === null ? undefined : vm.filters.patientStatus,
                "patientPostalCode.startsWith": !vm.filters.patientPostalCode ? undefined : vm.filters.patientPostalCode,
                "patientCity.containsNormalized": !vm.filters.patientCity ? undefined : vm.filters.patientCity,
                "healthFacilityId.equals": vm.filters.healthFacilityId === null ? undefined : vm.filters.healthFacilityId,
                "doctorId.equals": vm.filters.prescriberId === null ? undefined : vm.filters.prescriberId,
                "diseaseId.equals": vm.filters.diseaseId === null ? undefined : vm.filters.diseaseId,
                "diseaseId.in": vm.filters.diseaseIds,
                "trial.equals": vm.filters.trial === null ? undefined : vm.filters.trial,
                "assigneeId.equals": !vm.filters.assigneeId || vm.filters.assigneeId === 'notAssignee' ? undefined : vm.filters.assigneeId,
                "assigneeId.specified": vm.filters.assigneeId === 'notAssignee' ? false : undefined
            }, function (result) {
                vm.programs = result;
            });

            PatientTherapy.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                "status.in": vm.filters.status,
                "patientStatus.equals": vm.filters.patientStatus === null ? undefined : vm.filters.patientStatus,
                "patientPostalCode.startsWith": !vm.filters.patientPostalCode ? undefined : vm.filters.patientPostalCode,
                "patientCity.containsNormalized": !vm.filters.patientCity ? undefined : vm.filters.patientCity,
                "diseaseId.equals": vm.filters.diseaseId === null ? undefined : vm.filters.diseaseId,
                "diseaseId.in": vm.filters.diseaseIds,
                "trial.equals": vm.filters.trial === null ? undefined : vm.filters.trial,
                "healthFacilityId.equals": vm.filters.healthFacilityId === null ? undefined : vm.filters.healthFacilityId,
                "doctorId.equals": vm.filters.prescriberId === null ? undefined : vm.filters.prescriberId,
                "programId.equals": vm.filters.programId === null ? undefined : vm.filters.programId,
                "assigneeId.equals": !vm.filters.assigneeId || vm.filters.assigneeId === 'notAssignee' ? undefined : vm.filters.assigneeId,
                "assigneeId.specified": vm.filters.assigneeId === 'notAssignee' ? false : undefined,
                "hdj.equals": vm.filters.hdj === null ? undefined : vm.filters.hdj,
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'startDate') {
                    result.push('startDate,desc');
                }
                result.push('id,desc');
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.patientTherapies = data;
                vm.page = pagingParams.page;

                // TODO tuning (too many requests)
                const promises = [];
                angular.forEach(vm.patientTherapies, patientTherapy => {

                    patientTherapy.prescriptions = Prescription.query({
                        "patientTherapyId.equals": patientTherapy.id,
                        "sort": ["date,desc", "id,desc"]
                    });
                    if (vm.isAdmin) {
                        patientTherapy.pharmacyOrders = PharmacyOrder.query({
                            "patientTherapyId.equals": patientTherapy.id
                        });
                    }

                    // récupération de la date prévue du 1er questionnaire
                    promises.push(loadPlannedDateFirstVisit(patientTherapy));

                    // team
                    patientTherapy.team = [];
                    promises.push(CareTeam.getMembers(patientTherapy.patient.id)
                        .then(response => processTeam(response, patientTherapy)));
                });

                $q.all(promises).finally(() => {
                    const elapsed = Date.now() - vm.isLoading;
                    vm.isLoading = null;
                    $log.info('Loading took ' + elapsed + 'ms');
                });
            }

            function processTeam(response, patientTherapy) {

                const promises = [];
                const contractExecutorIds = [];
                const team = response.data
                    .filter(m => ['MAIN_DOCTOR', 'DOCTOR', 'PHARMACY', 'NURSE', 'NURSE_PLUS', 'NURSING', 'HAH', 'NURSING_HOME'].includes(m.role))
                    .sort(CareTeam.sort);

                team.forEach((m, i) => {
                    patientTherapy.team.push(m);
                    if (['MAIN_DOCTOR', 'PHARMACY', 'NURSE', 'NURSE_PLUS', 'NURSING', 'HAH', 'NURSING_HOME'].includes(m.role)) {
                        contractExecutorIds.push(m.entity.id);
                    }
                    if (['NURSING', 'HAH', 'NURSING_HOME'].includes(m.role)) {
                        promises.push(Practitioner.query({
                            "job.equals": "J60",
                            "healthFacilityId.equals": m.entity.id,
                            "sort": "lastName,asc"
                        }, (nurses) => {

                            angular.forEach(nurses, nurse => {
                                if (nurse.legalStatus === 'LIBERAL')
                                    contractExecutorIds.push(nurse.id);
                                patientTherapy.team.push({
                                    role: 'NURSE',
                                    type: 'Practitioner',
                                    entity: nurse
                                });
                            });

                            $log.debug(`${patientTherapy.id}: loaded ${nurses.length} nurses`);
                        }).$promise);
                    }
                    if (['PHARMACY'].includes(m.role)) {
                        promises.push(Practitioner.query({
                            "job.equals": "J21",
                            "healthFacilityId.equals": m.entity.id,
                            "sort": "lastName,asc"
                        }, (pharmacists) => {

                            angular.forEach(pharmacists, pharmacist => {
                                contractExecutorIds.push(pharmacist.id);

                                // on ajoute le pharmacien juste après dans la liste
                                // pour l'afficher à la suite de pharmacy au niveau de l'affichage
                                patientTherapy.team.splice(i + 1, 0, {
                                    role: 'PHARMACIST',
                                    type: 'Practitioner',
                                    entity: pharmacist
                                });
                            });

                            $log.debug(`${patientTherapy.id}: loaded ${pharmacists.length} pharmacists`);
                        }).$promise);
                    }
                });

                return $q.all(promises)
                    .then(() => loadContracts(patientTherapy, contractExecutorIds));
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }

            function loadContracts(patientTherapy, executorIds) {
                if (!executorIds || !executorIds.length || !patientTherapy.contractLineId) {
                    return Promise.resolve();
                }
                return HealthEntityContract.query({
                    "executorId.in": executorIds,
                    "signed": false,
                    "matchesContractLineId": patientTherapy.contractLineId
                }, contracts => {
                    patientTherapy.contracts = {};
                    for (const hec of contracts) {
                        patientTherapy.contracts[hec.executorId] = false;
                    }

                    $log.debug(`${patientTherapy.id}: loaded ${contracts.length} contracts to sign`);
                }).$promise;
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            });
        }

        function openPractitioner(practitioner) {

            Principal.hasAuthority('ROLE_ADMIN').then(hasAuthority => {
                if (hasAuthority) {
                    const url = $state.href('practitioner-detail', {id: practitioner.id});
                    $window.open(url, 'practitioner-detail-' + practitioner.id);
                } else {
                    return $uibModal.open({
                        templateUrl: 'app/entities/practitioner/practitioner-modal.html',
                        controller: 'PractitionerModalController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            practitioner: () => practitioner
                        }
                    });
                }
            });
        }

        function openFacility(facility) {
            Principal.hasAuthority('ROLE_ADMIN').then(hasAuthority => {
                if (hasAuthority) {
                    const url = $state.href('health-facility-detail', {id: facility.id});
                    $window.open(url, 'health-facility-detail-' + facility.id);
                } else {
                    return $uibModal.open({
                        templateUrl: 'app/entities/health-facility/health-facility-modal.html',
                        controller: 'HealthFacilityModalController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            healthFacility: () => facility
                        }
                    });
                }
            });
        }

        function loadPlannedDateFirstVisit(patientTherapy) {
            // Les types de visites à prendre en compte pour la première visite
            const VALID_VISIT_TYPES = ['PRO', 'STD_HOME', 'PHARMA_ITW', 'MEDICAL_CONSULT_EXT', 'NCC_PHONE', 'NCH_PHONE'];

            switch (patientTherapy.status) {
            case 'S11_VALIDATED' : // parcours à démarrer

                if (!patientTherapy.therapyId || !patientTherapy.day1Date) {
                    return;
                }

                return Therapy.get({id: patientTherapy.therapyId})
                    .$promise
                    .then((therapy) => {

                        // Trie les visites par la date relative d'échéance
                        therapy.plan.visits.sort((visit1, visit2) => visit1.relativeDueDateDays - visit2.relativeDueDateDays );

                        // Trouve la première visite à prendre en compte selon le type de visite
                        const firstRelevantVisit = therapy.plan.visits.find(visit => VALID_VISIT_TYPES.includes(visit.type));

                        patientTherapy.dueDateFirstVisit = moment(patientTherapy.day1Date).add(firstRelevantVisit.relativeDueDateDays, 'days').toDate();

                        $log.debug(`${patientTherapy.id}: loaded first visit ${patientTherapy.dueDateFirstVisit}`);
                    });

            case 'S20_ACTIVE' : // parcours en cours

                return Visit.query({
                    "patientTherapyId.equals": patientTherapy.id,
                    "type.in": VALID_VISIT_TYPES,
                    sort: ['dueDate', 'asc'],
                    size: 1
                }).$promise.then((visits) => {

                    if (visits && visits.length) {
                        const firstVisit = visits[0];

                        if (['TODO', 'IN_PROGRESS'].includes(firstVisit.status)) {
                            patientTherapy.dueDateFirstVisit = firstVisit.dueDate;
                        }

                    }

                    $log.debug(`${patientTherapy.id}: loaded first visit ${patientTherapy.dueDateFirstVisit}`);
                });
            }

        }

        function downloadCsv() {
            vm.exporting = true;
            PatientTherapy.exportCsv()
                .then(response => DownloadFile.downloadFile(transformExport(response), true))
                .catch(response => {
                    vm.exportError = response.data ? response.data.detail : 'Erreur';
                })
                .finally(() => {
                    vm.exporting = false;
                });
        }

        function transformExport(response) {
            return {
                data: new Blob([response.data], {type: response.headers('Content-Type')}),
                headers: response.headers
            };
        }
    }
})();
