(function() {
    'use strict';

    angular
        .module('continuumplatformApp')
        .factory('authExpiredInterceptor', authExpiredInterceptor);

    authExpiredInterceptor.$inject = ['$rootScope', '$q', '$injector', 'StateSaver'];

    function authExpiredInterceptor($rootScope, $q, $injector, StateSaver) {

        const ignoredPaths = [
            '/api/otp',
            '/api/otp-initial',
            '/api/account/reset-password/init',
            '/api/account/reset-password/check',
            '/api/account/reset-password/finish',
            '/api/authentication',
            '/api/account' // don't do this check on the account API to avoid infinite loop
        ];

        return {responseError};

        function responseError(response) {
            // If we have an unauthorized request we redirect to the login page
            if (response.status === 401 && angular.isDefined(response.data.path) && !ignoredPaths.includes(response.data.path)) {
                const Auth = $injector.get('Auth');
                const to = $rootScope.toState;
                const params = $rootScope.toStateParams;
                Auth.logout();
                if (to.name !== 'accessdenied' && to.name !== 'login') {
                    StateSaver.storePreviousState(to.name, params);
                }
                const $state = $injector.get('$state');
                $state.go('login');
            }
            return $q.reject(response);
        }
    }
})();
