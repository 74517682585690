(function () {
    'use strict';

    const visitIntakeRecord = {
        bindings: {
            data: '<',
            visit: '<',
            patientTherapy: '<',
            onValidate: '&'
        },
        controller: VisitIntakeRecordController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-intake-record.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitIntakeRecord', visitIntakeRecord);

    VisitIntakeRecordController.$inject = ['_', '$log', '$filter', '$rootScope', '$scope', '$window', '$timeout', '$uibModal', 'Visit', 'IntakeRecord', 'MedicationVariant'];

    function VisitIntakeRecordController(_, $log, $filter, $rootScope, $scope, $window, $timeout, $uibModal, Visit, IntakeRecord, MedicationVariant) {
        const vm = this;

        vm.step = 'INTAKE_RECORD';

        vm.planningStartDate = null;
        vm.maxStartDate = moment().subtract(1, 'day').toDate();
        vm.minStartDate = moment().subtract(1, 'years').toDate();
        vm.maxStartDateFormat = moment(vm.maxStartDate).format('YYYY[-]MM[-]DD');
        vm.minStartDateFormat = moment(vm.minStartDate).format('YYYY[-]MM[-]DD');
        vm.selectedWeek = -1;
        vm.started = null;
        vm.intakeRecordsByDate = {};
        vm.countSelectedDosages = 0;

        vm.onClickSelectVariant = onClickSelectVariant;
        vm.onClickAddVariant = onClickAddVariant;
        vm.onClickMinus = onClickMinus;
        vm.onClickPlus = onClickPlus;
        vm.reset = reset;
        vm.next = next;
        vm.previous = previous;
        vm.config = config;

        // TODO intakeRecordsByDate devrait toujours être utilisé pour savoir si un relevé a déjà été fait pour une date donnée.

        vm.$onInit = () => {
            generateMedications().then(() => {
                load().then(started => {
                    $log.info("Relevé de prise déjà démarré : " + started);
                    vm.started = started;
                    if (!started) {
                        watchPlanningStartDate();
                    }
                });
            });
        };

        vm.$onDestroy = () => {
            if (vm.unWatchPlanningStartDate) {
                vm.unWatchPlanningStartDate();
            }
        };

        function watchPlanningStartDate() {
            vm.unWatchPlanningStartDate = $scope.$watch("vm.planningStartDate", onChangePlanningStartDate);
        }

        function start() {
            vm.data = generateData();
            vm.started = true;
        }

        function reset() {
            vm.data = [];
            vm.calendar = null;
            loadPreviousContext().then(started => {
                $log.info("Relevé de prise précédent : " + started);
                vm.started = started;
                if (!started && !vm.unWatchPlanningStartDate) {
                    watchPlanningStartDate();
                }
            });
        }

        function next() {
            if(!vm.countSelectedDosages) {
                alert("Aucun dosage sélectionné !");
                return;
            }
            $window.scroll(0, 0);
            if(!vm.started) {
                $log.info("Démarrage du relevé de prise");
                start();
            }
            if(vm.selectedWeek === -1) {
                $log.info("Génération du calendrier");
                vm.calendar = generateCalendar();
            }
            if(vm.selectedWeek === vm.calendar.week.length - 1) {
                $log.info("Validation du relevé de prise");
                finish();
            } else {
                vm.selectedWeek++;
            }
        }

        function previous() {
            $window.scroll(0, 0);
            vm.selectedWeek--;
        }

        function config() {
            $window.scroll(0, 0);
            vm.selectedWeek = -1;
        }

        function finish() {

            // on supprime des données à retourner les données déjà présentes dans le relevé de prise côté serveur.
            const data = vm.data.filter(d => !vm.intakeRecordsByDate[generateDollarKey(d)]);

            vm.onValidate({data});
        }

        function generateMedications() {
            vm.medications = {};
            const oralMedications = vm.patientTherapy.medications
                .filter(m => m.routeType === 'ORAL');
            for (const medication of oralMedications) {
                medication.selectedDosages = [];
                vm.medications[medication.id] = medication;
            }

            // get variants
            return new Promise((resolve, reject) => {
                return generateAvailableDosages(Object.keys(vm.medications)).then(() => {
                    return resolve();
                });
            });
        }

        /**
         * Génère les données du relevé de prise à partir du brouillon ou du précédent relevé
         *
         * @returns {Promise} Promise avec un booléen indiquant si le relevé de prise a déjà été démarré
         */
        function load() {
            if (vm.data && vm.data.length) {
                // chargement des données du brouillon
                return loadDraftData();
            } else {
                // chargement des données du relevé de prise précédent
                return loadPreviousContext();
            }
        }

        function loadDraftData() {
            vm.resetable = true;
            vm.planningStartDate = moment(vm.data[0].date).toDate();

            // dosages sélectionnés pour chaque médicament à partir du brouillon
            Object.entries($filter('groupBy')(vm.data, 'medicationId')).forEach(entry => {
                const [medicationId, records] = entry;
                vm.medications[medicationId].selectedDosages = $filter('unique')(records, 'doseMg').map(v => v.doseMg);
                vm.countSelectedDosages += vm.medications[medicationId].selectedDosages.length;
            });

            return Promise.resolve(true);
        }

        function loadPreviousContext() {
            return IntakeRecord.query({
                'patientTherapyId.equals': vm.patientTherapy.id,
            }).$promise
                .then(processPreviousIntakeRecords);
        }

        function processPreviousIntakeRecords(intakeRecords) {
            return new Promise((resolve, reject) => {

                if (!intakeRecords.length) {
                    return resolve(false);
                }

                const intakeRecordsSortedByDateDesc = intakeRecords
                    .sort((a, b) => new Date(b.date) - new Date(a.date));

                vm.planningStartDate = moment(intakeRecordsSortedByDateDesc[0].date).add(1, 'day').toDate();

                const selectedMedicationDosages = new Map();

                intakeRecordsSortedByDateDesc
                    .forEach((r) => {
                        vm.intakeRecordsByDate[generateDollarKey(r)] = r;
                        if (!selectedMedicationDosages.has(r.medicationId)) {
                            selectedMedicationDosages.set(r.medicationId, new Set());
                        }
                        selectedMedicationDosages.get(r.medicationId).add(r.doseMg);
                    });

                selectedMedicationDosages.forEach((dosages, medicationId) => {
                    vm.medications[medicationId].selectedDosages = [...dosages];
                    vm.countSelectedDosages += vm.medications[medicationId].selectedDosages.length;
                });

                vm.data = generateData();
                return resolve(true);

            });
        }

        function generateAvailableDosages(idList) {
            return MedicationVariant.query({
                "medicationId.in": idList,
            }).$promise.then(mapVariantsToAvailableDosages);
        }

        function mapVariantsToAvailableDosages(allVariants) {
            Object.entries($filter('groupBy')(allVariants, 'medicationId')).forEach(entry => {
                const [medicationId, medicationVariants] = entry;
                vm.medications[medicationId].availableDosages = $filter('unique')(medicationVariants, 'doseMg').map(v => v.doseMg);
            });
            return allVariants;
        }

        function addWeekIfNotExist(calendar, date) {
            const c = Object.assign({}, calendar);
            const d = moment(date);

            if (!c.week.get(`${d.year()}_${d.week()}`)) {
                c.week.set(`${d.year()}_${d.week()}`, {
                    timestamp: d.valueOf(),
                    firstDayOfWeekFormat: d.startOf('week').format('LL'),
                    lastDayOfWeekFormat: d.endOf('week').format('LL'),
                    day: [],
                });
            }

            return c;
        }

        function createDay(date) {
            const d = moment(date);
            return {
                date: date.toDate(),
                dateFormat: d.format('LL'),
                monthFormat: d.format('MMMM'),
                month: d.month(),
                day: d.date(),
                formatDay: d.format('dddd D MMMM'),
                week: d.week(),
                releve: {}
            };
        }

        function onChangePlanningStartDate(newVal, oldVal) {
            if (!newVal) {
                vm.calendar = null;
                return;
            }

            if (!moment(newVal).isSameOrAfter(moment().startOf('day').subtract(1, 'years'))) {
                $log.warn("Le plan de prise doit ne peut pas aller jusqu'à un an.");
            } else if (newVal && !oldVal) {
                vm.data = generateData();
            } else if (moment(newVal).isAfter(oldVal, 'day')) {
                vm.data = vm.data.filter((d) => moment(d.date).isSameOrAfter(moment(newVal), 'day'));
            } else if (moment(newVal).isBefore(oldVal, 'day')) {
                const newData = generateData();
                newData.forEach(newD => {
                    vm.data.forEach(oldD => {
                        if (newD.data === oldD.date && newD.medicationId === oldD.medicationId && newD.doseMg === oldD.doseMg) {
                            newD.value = oldD.value;
                        }
                    });
                }); // cette copie ci-dessus n'est plus utile depuis que les saisies écrasent le futur
                vm.data = newData;
            }
            vm.resetable = true;
        }

        function onClickSelectVariant(medicationId, doseMg) {
            if(vm.selectingVariant) {
                return;
            }
            vm.selectingVariant = true;
            if (vm.medications[medicationId].selectedDosages.includes(doseMg)) {
                vm.medications[medicationId].selectedDosages = vm.medications[medicationId].selectedDosages.filter(existing => existing !== doseMg);
                vm.countSelectedDosages--;
                if(vm.data.length) {
                    vm.data = vm.data.filter((d) => !(d.medicationId === parseInt(medicationId) && d.doseMg === doseMg));
                }
            } else {
                vm.medications[medicationId].selectedDosages.push(doseMg);
                vm.countSelectedDosages++;
                if(vm.started) {
                    const nbDay = calculateNumberOfDays();
                    generateDataForMedicationDosage(vm.data, parseInt(medicationId), doseMg, nbDay);
                }
            }
            vm.selectingVariant = false;
        }

        /**
         * Ajouter un dosage
         */
        function onClickAddVariant(day, medicationId, doseMg) {
            vm.medications[medicationId].selectedDosages.push(doseMg);
            vm.calendar.week.forEach((w) => {
                w.day.forEach((d) => {
                    // on ajoute le variant uniquement pour le jour sélectionné et ceux après
                    if (moment(d.date).isSameOrAfter(moment(day.date), 'day')) {
                        const newData = {
                            date: moment(d.date).format('YYYY-MM-DD'),
                            medicationId: parseInt(medicationId),
                            value: null,
                            doseMg: doseMg
                        };
                        d.releve[medicationId][doseMg] = {
                            key: generateDollarKey(newData),
                            data: newData
                        };
                        vm.data.push(newData);
                    }
                });
            });
            vm.countSelectedDosages = _.uniqBy(vm.data, (d) => d.medicationId + '$' + d.doseMg).length;
        }

        function onClickMinus(data) {
            data.value = !data.value ? 0 : +data.value - 1;
            updateFollowingDays(data);
        }

        function onClickPlus(data) {
            data.value = +data.value + 1;
            updateFollowingDays(data);
        }

        function updateFollowingDays(data) {
            const day = moment(data.date);
            const medicationId = data.medicationId;
            vm.calendar.week.forEach((w) => {
                w.day.forEach(followingData => {
                    if (moment(followingData.date).isAfter(day, 'day')
                        && !vm.intakeRecordsByDate[generateDollarKey(followingData.releve[medicationId][data.doseMg].data)]) {
                        followingData.releve[medicationId][data.doseMg].data.value = data.value;
                    }
                });
            });
        }

        function generateDollarKey(data) {
            return data.date + '$' + data.medicationId + '$' + data.doseMg;
        }

        function generateData() {
            // limitation de la date de façon à éviter des problèmes de generation du planning
            if (!moment(vm.planningStartDate).isSameOrAfter(moment().startOf('day').subtract(1, 'years'))) {
                return;
            }

            const data = [];

            const nbDay = calculateNumberOfDays();

            Object.values(vm.medications).forEach(medication => {
                medication.selectedDosages.forEach(doseMg => {
                    generateDataForMedicationDosage(data, medication.id, doseMg, nbDay);
                });
            });

            return data;
        }

        function calculateNumberOfDays() {
            const startDate = moment(vm.planningStartDate).utcOffset(0).startOf('day');
            const endDate = moment().subtract(moment().utcOffset(), 'minutes').startOf('day');
            return endDate.diff(startDate, 'day');
        }

        function generateDataForMedicationDosage(data, medicationId, doseMg, nbDay) {
            for (let i = 0; i < nbDay; i++) {
                const date = moment(vm.planningStartDate).add({days: i}).format('YYYY-MM-DD');
                const intakeRecord = vm.intakeRecordsByDate[generateDollarKey({date, medicationId, doseMg})];
                const value = intakeRecord ? intakeRecord.value : null;
                data.push({date, medicationId, doseMg, value});
            }
        }

        /**
         * Génère le calendrier à partir des données déjà présentes et de la liste des spécialités pharmaceutiques
         */
        function generateCalendar() {
            let calendar = {week: new Map()};
            vm.data.forEach((d, index) => {
                const date = moment(d.date);

                calendar = addWeekIfNotExist(calendar, date);
                const week = calendar.week.get(`${date.year()}_${date.week()}`);
                let day = week.day.find((d) => date.isSame(moment(d.date), 'day'));

                if (!day) {
                    day = createDay(date);
                    calendar.week.get(`${date.year()}_${date.week()}`).day.push(day);
                }

                if (!day.releve[d.medicationId]) {
                    day.releve[d.medicationId] = {};
                }

                // ajout des données pour le relevé au jour
                day.releve[d.medicationId][d.doseMg] = {
                    key: generateDollarKey(d), // génération d'un nom unique pour les inputs du formulaire
                    data: d
                };
            });

            // on transforme la map en tableau
            calendar.week = [...calendar.week.values()].sort((w1, w2) => {
                return w1.timestamp - w2.timestamp;
            });

            return calendar;
        }
    }
})();
