(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .factory('StateSaver', StateSaver);

    StateSaver.$inject = ['$sessionStorage'];

    function StateSaver($sessionStorage) {

        return {getPreviousState, resetPreviousState, storePreviousState};

        function getPreviousState() {
            return $sessionStorage.previousState;
        }

        function resetPreviousState() {
            delete $sessionStorage.previousState;
        }

        function storePreviousState(previousStateName, previousStateParams) {
            $sessionStorage.previousState = {"name": previousStateName, "params": previousStateParams};
        }
    }
})();
