(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('Address', Address);

    Address.$inject = ['$http'];

    function Address($http) {

        return {
            searchAddress: (search, postalCode) => {
                return new Promise((resolve, reject) => {
                    if (!search) {
                        resolve();
                    }
                    return $http({
                        method: 'GET',
                        url: `https://api-adresse.data.gouv.fr/search/?q=${encodeURI(search)}&type=housenumber&autocomplete=1&limit=7${postalCode ? '&postcode=' + postalCode : ''}`
                    }).then(function successCallback(response) {
                        let matchingAddress = response.data.features;
                        resolve(matchingAddress);

                    }, function errorCallback(response) {
                        reject(response);
                    });

                });
            },
            searchCity: (postalCode) => {
                return new Promise((resolve, reject) => {
                    if (!postalCode) {
                        resolve([]);
                    } else {
                        return $http({
                            method: 'GET',
                            url: `https://api-adresse.data.gouv.fr/search/?q=${postalCode}&type=municipality&autocomplete=1&limit=50`
                        }).then(function successCallback(response) {
                            let matchingCity = response.data.features.sort((c1, c2) => {
                                return c1.properties.label.localeCompare(c2.properties.label);
                            }).map((c) => {
                                c.properties.label = `${c.properties.postcode} ${c.properties.name}`;
                                return c;
                            });

                            resolve(matchingCity);
                        }, function errorCallback(response) {
                            reject(response);
                        });
                    }

                });
            }
        };
    }
})();
