(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('PrescriptionItem', PrescriptionItem);

    PrescriptionItem.$inject = ['$resource', 'DateUtils', 'PatientTreatment', 'PatientTreatmentPhase'];

    function PrescriptionItem($resource, DateUtils, PatientTreatment, PatientTreatmentPhase) {
        const resourceUrl = 'api/prescription-items/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        const decoded = angular.fromJson(data);
                        if (angular.isArray(decoded)) {
                            const transformed = [];
                            angular.forEach(decoded, function (v, k) {
                                transformed.push(transformResponse(v));
                            });
                            return transformed;
                        } else {
                            return decoded;
                        }
                    }
                    return data;
                }
            },
            'get': {
                method: 'GET',
                transformResponse: transformResponse
            }
        });

        function transformResponse(data) {
            if (data) {
                data = angular.fromJson(data);
                data.startDate = DateUtils.convertLocalDateFromServer(data.startDate);
                data.patientTreatmentPhase = PatientTreatmentPhase.transformResponse(data.patientTreatmentPhase);
            }
            return data;
        }
    }
})();
