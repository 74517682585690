(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientEditAddressController', PatientEditAddressController);

    PatientEditAddressController.$inject = ['$filter', '$scope', '$timeout', '$uibModalInstance', 'entity', 'Patient', 'Address'];

    function PatientEditAddressController($filter, $scope, $timeout, $uibModalInstance, entity, Patient, Address) {
        const vm = this;

        vm.cancel = cancel;
        vm.save = save;
        vm.searchAddress = searchAddress;
        vm.onSelectAddress = onSelectAddress;

        vm.$onInit = () => {
            vm.patientId = entity.id;
            vm.patientAge = entity.age;

            vm.hasUserAccount = !!entity.userId;

            vm.fullName = $filter('patientName')(entity);

            vm.addressInvalid = false;
            vm.address = {properties: {
                label: `${entity.address || ''} ${entity.postalCode || ''} ${entity.city || ''}`
            }};

            vm.form = {
                address: entity.address,
                additionalAddress: entity.additionalAddress,
                postalCode: entity.postalCode,
                city: entity.city,
                country: entity.country,
                email: entity.email,
                phone: entity.phone,
                mobilePhone: entity.mobilePhone,
                legalRepresentative: entity.legalRepresentative,
                addressNotes: entity.addressNotes,
            };

            vm.notify = false;
        };

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;

            // pour éviter d'avoir des problèmes d'affichage si on ne saisi 200 saut de ligne
            vm.form.addressNotes = vm.form.addressNotes ? vm.form.addressNotes.trim() : '';

            Patient.updateAddress({
                id: vm.patientId
            }, vm.form, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess() {
            $uibModalInstance.close();
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function searchAddress(search) {
            vm.addressInvalid = !!search;

            if (!search) {
                vm.form.address = null;
                vm.form.postalCode = null;
                vm.form.city = null;

                vm.form.latitude = null;
                vm.form.longitude = null;
                vm.form.cityCode = null;
            }

            if (!search || search.length < 3) {
                return;
            }

            return Promise.all([
                Address.searchAddress(search),
                Address.searchCity(search)]
            ).then((values) => {
                return values.flat();
            });
        }

        function onSelectAddress($item, $model, $label, $event)  {
            vm.addressInvalid = false;
            if (vm.form.address !== $item.properties.name) {
                vm.form.address = ['housenumber', 'street'].includes($item.properties.type) ? $item.properties.name : null;
                vm.form.postalCode = $item.properties.postcode ? $item.properties.postcode : vm.form.postalCode;
                vm.form.city = $item.properties.city ? $item.properties.city : vm.form.city;

                vm.form.latitude = $item.geometry && $item.geometry.coordinates && $item.geometry.coordinates[1] ? $item.geometry.coordinates[1] : vm.form.latitude;
                vm.form.longitude = $item.geometry && $item.geometry.coordinates && $item.geometry.coordinates[0] ? $item.geometry.coordinates[0] : vm.form.longitude;
                vm.form.cityCode = $item.properties.citycode ? $item.properties.citycode : vm.form.cityCode;
            }
        }

    }
})();
