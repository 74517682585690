(function() {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('JhiMetricsMonitoringController', JhiMetricsMonitoringController);

    JhiMetricsMonitoringController.$inject = ['$scope','JhiMetricsService', '$uibModal'];

    function JhiMetricsMonitoringController ($scope, JhiMetricsService, $uibModal) {
        var vm = this;

        vm.metrics = {};
        vm.refresh = refresh;
        vm.refreshThreadDumpData = refreshThreadDumpData;
        vm.servicesStats = {};
        vm.updatingMetrics = true;

        vm.refresh();

        function refresh () {
            vm.updatingMetrics = true;
            JhiMetricsService.getMetrics().then(function (metrics) {

                vm.endpoints = [];
                angular.forEach(metrics.services, function (methods, service) {
                    angular.forEach(methods, function (details, method) {
                        vm.endpoints.push({
                            url: service,
                            method: method,
                            count: details.count,
                            mean: details.mean
                        });
                    });
                });

                vm.metrics = metrics;
                vm.updatingMetrics = false;

            }, function (result) {
                vm.metrics = result.data;
                vm.updatingMetrics = false;
            });
        }

        function refreshThreadDumpData () {
            JhiMetricsService.threadDump().then(function(data) {
                $uibModal.open({
                    templateUrl: 'app/system/metrics/metrics.modal.html',
                    controller: 'JhiMetricsMonitoringModalController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        threadDump: function() {
                            return data.threads;
                        }

                    }
                });
            });
        }


    }
})();
