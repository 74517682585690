(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PrescriptionDetailController', PrescriptionDetailController);

    PrescriptionDetailController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$timeout', '$window', '$sce', '$uibModal', 'previousState', 'entity', 'Prescription', 'Patient', 'PatientTherapy', 'Practitioner', 'PrescriptionItem', 'HealthFacility', 'Media', 'Upload', 'MedicationVariant', 'PatientTreatmentPhase', 'Principal', 'Inbox'];

    function PrescriptionDetailController($scope, $rootScope, $state, $stateParams, $timeout, $window, $sce, $uibModal, previousState, entity, Prescription, Patient, PatientTherapy, Practitioner, PrescriptionItem, HealthFacility, Media, Upload, MedicationVariant, PatientTreatmentPhase, Principal, Inbox) {
        const vm = this;

        vm.deletePrescription = deletePrescription;
        vm.print = print;

        vm.prescription = entity;

        vm.previousState = previousState;

        Patient.get({id: vm.prescription.patientId}).$promise.then(patient => vm.patient = patient);

        vm.anonymous = false;

        loadItems();

        if (vm.prescription.messageId)
            $timeout(function () {
                Inbox.readMessage(vm.prescription.messageId).then(function (response) {
                    // TODO improve with event listener
                    $rootScope.unreadMessages -= response.data;
                    $rootScope.$emit('continuumplatformApp:messageRead', response.data);
                });
            });

        Principal.identity().then(function (account) {
            vm.account = account;
            vm.isPharmacist = account.practitioner && ['J21', 'J38'].includes(account.practitioner.job);
            if (account.patient) {
                vm.defaultPreviousStep = {
                    name: 'prescription'
                };
            } else {
                vm.defaultPreviousStep = {
                    name: vm.isPharmacist ? 'patient-detail.pharmaceutics' : 'patient-detail.follow-up',
                    params: {id: vm.prescription.patientId}
                };
            }
        });

        if (vm.prescription.mediaId) {

            vm.trustedInlineUrl = $sce.trustAsResourceUrl('/api/media/' + vm.prescription.mediaId + '/download?disposition=inline');

        } else {

            if (vm.prescription.prescriber && vm.prescription.prescriber.healthFacilityId)
                vm.healthFacility = HealthFacility.get({id: vm.prescription.prescriber.healthFacilityId, view: 'summary'});

            var unsubscribe = $rootScope.$on('continuumplatformApp:prescriptionUpdate', function (event, result) {
                vm.prescription = result;
            });
            $scope.$on('$destroy', unsubscribe);
        }

        function loadItems() {
            PrescriptionItem.query({
                "prescriptionId.equals": vm.prescription.id,
                "sort": "createdDate"
            }, function (items) {
                vm.prescription.items = items;
            });
        }

        function deletePrescription() {
            $uibModal.open({
                templateUrl: 'app/activity/prescription/prescription-delete-dialog.html',
                controller: 'PrescriptionDeleteController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: [function () {
                        return vm.prescription;
                    }]
                }
            }).result.then(function () {
                if (vm.previousState.name)
                    $state.go(vm.previousState.name, vm.previousState.params, {reload: true});
                else
                    $state.go(vm.defaultPreviousStep.name, vm.defaultPreviousStep.params, {reload: true});
            }, function () {
            });
        }

        function print(anonymous) {
            vm.anonymous = anonymous;
            $timeout($window.print, 0);
        }
    }
})();
