(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PrescriptionEditionController', PrescriptionEditionController);

    PrescriptionEditionController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Prescription', 'Patient', 'PatientTherapy', 'Practitioner', 'PrescriptionItem', 'Principal', 'MedicationVariant', 'Therapy', 'Upload'];

    function PrescriptionEditionController($timeout, $scope, $stateParams, $uibModalInstance, entity, Prescription, Patient, PatientTherapy, Practitioner, PrescriptionItem, Principal, MedicationVariant, Therapy, Upload) {
        var vm = this;

        vm.prescription = entity;

        vm.clear = clear;
        vm.save = save;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            Prescription.comment({id: vm.prescription.id}, vm.prescription.comment, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $scope.$emit('continuumplatformApp:prescriptionUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

    }
})();
