(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PrescriptionImportController', PrescriptionImportController);

    PrescriptionImportController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'notify', 'Prescription', 'Patient', 'PatientTherapy', 'Practitioner', 'PrescriptionItem', 'account', 'MedicationVariant', 'Therapy', 'Upload', 'CompressImage', 'CareTeam'];

    function PrescriptionImportController($timeout, $scope, $stateParams, $uibModalInstance, entity, notify, Prescription, Patient, PatientTherapy, Practitioner, PrescriptionItem, account, MedicationVariant, Therapy, Upload, CompressImage, CareTeam) {
        var vm = this;

        vm.clear = clear;
        vm.save = save;

        vm.prescription = entity;
        vm.notify = notify;

        if(!vm.prescription.patientId)
            throw new Error('Missing patient id');

        Patient.get({id: vm.prescription.patientId}, patient =>
            vm.patient = patient);

        loadPractitioners();

        // init patient therapies if patient is specified  TODO for admin
        if (!vm.prescription.patientTherapyId) {
            loadPatientTherapies();
        } else {
            vm.patienttherapies = [PatientTherapy.get({id: vm.prescription.patientTherapyId})];
        }

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (!vm.prescription.id) {
                Prescription.save(vm.prescription, onSaveSuccess, onSaveError);
            } else {
                // already saved, retry upload
                onSaveSuccess(vm.prescription);
            }
        }

        function upload(result) {
            if (vm.file.type.startsWith('image/')) {
                return CompressImage.compress(vm.file).then( (compressImageFile) => {
                    vm.file = compressImageFile;
                    return uploadFile(result, compressImageFile);
                });
            } else {
                return uploadFile(result, vm.file);
            }
        }

        function uploadFile(result, file) {
            return Upload.upload({
                url: 'api/prescriptions/' + result.id + '/upload',
                method: 'PUT',
                arrayKey: '',
                data: {file, notify}
            }).then(function (resp) {
                return new Promise((resolve, reject) => {
                    onFinish(result);
                    resolve();
                });
            }, function (resp) {
                vm.isSaving = false;
                // TODO partial error...
            }, function (evt) {
                vm.uploadProgress = parseInt(100.0 * evt.loaded / evt.total);
            });
        }

        function onSaveSuccess(result) {

            // if modifying & no added files, finish
            if (!vm.file) {
                onFinish(result);
                return;
            }

            vm.prescription.id = result.id;
            upload(result);

        }

        function onFinish(result) {
            $scope.$emit('continuumplatformApp:prescriptionUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function loadPractitioners() {
            CareTeam.getMembers(vm.prescription.patientId).then(response => {
                vm.prescribers = response.data
                    .filter(filterPrescribers)
                    .map(m => m.entity);
            });
        }

        // médecins ou IPA
        function filterPrescribers(member) {
            return member.entity.job === 'J10'
                || (member.entity.job === 'J60' && member.entity.speciality !== null);
        }

        function loadPatientTherapies() {
            PatientTherapy.query({
                "patientId.equals": vm.prescription.patientId,
                "status.in": ["S20_ACTIVE", "S30_PAUSED"]
            }, function (result) {
                vm.patienttherapies = result;
                if (result.length === 1)
                    vm.prescription.patientTherapyId = result[0].id;
            });
        }
    }
})();
