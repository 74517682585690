(function () {
    'use strict';

    const pharmaItwTreatment = {
        bindings: {
            data: '=',
            patient: '<',
            patientTherapy: '<',
            visit: '=',
            questions: '<'
        },
        controller: pharmaItwTreatmentController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/pharma-itw-treatment.html'
    };

    angular
        .module('continuumplatformApp')
        .component('pharmaItwTreatment', pharmaItwTreatment);

    pharmaItwTreatmentController.$inject = ['$log', '$rootScope', '$scope', '$uibModal', '_', 'PharmAnalysis'];

    function pharmaItwTreatmentController($log, $rootScope, $scope, $uibModal, _, PharmAnalysis) {

        const vm = this;

        vm.step = 'PHARMA_ITW_TREATMENT';
        vm.state = 'initializing';
        vm.currentPage = 1;
        vm.maxPage = 2;

        vm.openModal = openModal;
        vm.downloadURI = downloadURI;
        vm.onClickNext = onClickNext;
        vm.onClickPrevious = onClickPrevious;
        vm.finish = finish;

        vm.$onInit = function () {
            loadPharmAnalysis().then(pharmAnalysis => {
                if (pharmAnalysis === null) {
                    createEmptyPharmAnalysis().then(init).catch(onError);
                } else {
                    init(pharmAnalysis);
                }
            }).catch(onError);
        };

        vm.$onDestroy = () => {
            // nothing to do
        };

        function init(pharmAnalysis) {
            vm.pharmAnalysis = pharmAnalysis;
            vm.visit.data.pharmAnalysisId = vm.pharmAnalysis.id;
            vm.state = 'initialized';
        }

        function onError() {
            vm.state = 'error';
        }

        function loadPharmAnalysis() {
            return PharmAnalysis.query({"visitId.equals": vm.visit.id})
                .$promise
                .then(pharmAnalysis => {
                    if (pharmAnalysis && pharmAnalysis.length) {
                        return pharmAnalysis[0];
                    }
                    return null;
                });
        }

        function createEmptyPharmAnalysis() {
            return PharmAnalysis.save({
                id: null,
                visitId: vm.visit.id,
                validated: false,
                text: '',
                media: [],
                data: null,
            }).$promise;
        }

        function openModal(prescription, media) {
            $uibModal.open({
                templateUrl: 'app/activity/prescription/prescriptions-detail.modal.html',
                controller: 'PrescriptionsDetailModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('prescription');
                        $translatePartialLoader.addPart('healthFacility');
                        return $translate.refresh();
                    }],
                    entity: () => ({prescription, media}),
                }
            }).result.then(function (result) {
                // nothing
            }, function () {
                // nothing
            });
        }

        function downloadURI(uri, name) {
            const downloadLink = angular.element('<a></a>');
            downloadLink.attr('href', uri);
            if (name) {
                downloadLink.attr('download', name);
            }

            downloadLink[0].click();
        }

        function onClickNext() {
            vm.state = 'updating';
            updatePharmAnalysis()
                .then(() => {
                    vm.currentPage = vm.currentPage + 1;
                })
                .catch((error) => {
                    $log.error(error);
                    alert("Une erreur est survenue lors de l'enregistrement. Veuillez réessayer");
                })
                .finally(() => {
                    vm.state = 'initialized';
                });
        }

        function onClickPrevious() {
            vm.currentPage = vm.currentPage - 1;
        }

        function finish() {
            vm.pharmAnalysis.data.hasMedicationPlanWithData = vm.pharmAnalysis.data.medications.reduce((hasMedicationPlanWithData, m) => hasMedicationPlanWithData || !!m.medicationPlan.schedule, false);

            vm.state = 'finishing';
            updatePharmAnalysis()
                .then(() => {
                    $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                        step: vm.step
                    });
                })
                .catch((error) => {
                    $log.error(error);
                    vm.state = 'initialized';
                    alert("Une erreur est survenue lors de l'enregistrement. Veuillez réessayer");
                });
        }

        /**
         * Permet de mettre à jour l'analyse pharmaceutique.
         * @returns {Promise} le résultat de la mise à jour
         */
        function updatePharmAnalysis() {
            return PharmAnalysis.update({
                id: vm.pharmAnalysis.id
            }, {
                text: vm.pharmAnalysis.text,
                visitId: vm.pharmAnalysis.visitId,
                data: vm.pharmAnalysis.data,
                validated: vm.pharmAnalysis.validated ? vm.pharmAnalysis.validated : false
            }).$promise;
        }


    }

})();
